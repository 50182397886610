html, body, #root {
  height: 100%;
}

body {
  font-family: "Open Sans", sans-serif;
  background: url('../public/assets/background-stars.jpg') fixed center center;
  background-size: cover;
  color: #e3e3e3;
}

body:has(.requires-no-scroll) {
  overflow: hidden;
}

a:hover, a:active, a:visited, a:link {
  text-decoration: none;
  color: #fff;
}

/* START - SCROLLBAR */
* {
  scrollbar-width: thin;
  scrollbar-color: #1c1c1c transparent;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #1c1c1c;
  border-radius: 8px;
  border: 2px solid #272727;
}
/* END - SCROLLBAR */

.menu {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 12px;
}

.menu-list {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
}

.menu-item {
  padding: 8px 12px;
  margin: 12px 0;
}

.menu-item a {
  border: 2px solid #ffffff;
  padding: 8px;
}

.menu-item a:hover {
  background-color: #ffffff;
  color: #000000;
}

.menu-item-icon a {
  border: 0;
  padding: 0;
}

.menu-item-icon a:hover {
  background-color: transparent;
}

.menu-item-icon img {
  width: 16px;
  height: 16px;
}

/*
=====
PAGES
=====
*/

/* HOME */
.home-banner {
  width: 100%;
  height: 250px;
  text-align: center;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  margin-bottom: 24px;
  padding: 16px;
  box-sizing: border-box;
}

.home-banner div {
  position: absolute;
  bottom: 16px;
  right: 16px;
  font-size: 18px;
  font-weight: 200;
  letter-spacing: 2px;
  text-align: right;
  width: 100%;
}

.home-banner div span {
  background-color: #ccc;
  color: #000;
}

.home-schedule {
  text-align: center;
  margin: 32px 0;
}

.home-schedule a {
  padding: 8px;
  border: 2px solid #ffffff;
  text-transform: uppercase;
  text-align: center;
}

.home-schedule a:hover {
  background-color: #ffffff;
  color: #000000;
}

.home-club-container {
  width: 100%;
  text-align: center;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  box-sizing: border-box;
  padding: 12px 0;
}

.home-club-title {
  display: block;
  width: 100%;
  text-transform: uppercase;
  color: #fff;
  font-weight: 600;
  margin-bottom: 12px;
}

.home-club-description-container {
  margin: 24px auto;
  max-width: 420px;
}

.home-club-description {
  font-size: 16px;
  color: #fff;
  letter-spacing: 2px;
  font-weight: 200;
}

.home-articles {
  background-color: #fff;
  color: #000;
}

.home-articles a {
  color: #000;
}

.home-articles-container {
  padding: 18px;
  max-width: 800px;
  margin: auto;
}

.home-articles-title {
  display: block;
  width: 100%;
  text-transform: uppercase;
  color: #fff;
  font-weight: 600;
  margin-bottom: 12px;
  text-align: center;
  color: #000;
}

@keyframes bounce {
  from {
    transform: scale(1.2);
  }
  to {
    transform: scale(1);
  }
}

.home-chat {
  background-color: rgb(37, 211, 102);
  border-radius: 100%;
  width: 54px;
  height: 54px;
  position: fixed;
  bottom: 24px;
  right: 32px;
  animation: bounce .4s 3 alternate;
}

.home-chat-text {
  position: absolute;
  font-size: 12px;
  top: -54px;
  width: 98px;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  border-radius: 12px;
  padding: 4px;
  color: #000;
}

.home-chat img {
  width: 24px;
  height: 24px;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* SCHEDULES */
.schedules-container {
  height: 100%;
}

.schedules-frame {
  border: 0;
}

/* ARTICLE */
.article-container {
  max-width: 1000px;
  margin: auto;
  padding: 0 24px;
}

.article-container img {
  max-width: 900px;
  max-height: 436px;
  border-radius: 24px;
  box-sizing: border-box;
}

.article-container h1 {
  font-size: 24px;
}

.article-container h2 {
  font-size: 18px;
}

.article-container h3 {
  font-size: 16px;
}

/* CHECKOUT */
.checkout-container {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  box-sizing: border-box;
  background-color: #fff;
  color: #000;
  height: calc(100% - 84px);
}

.checkout-club-container {
  margin: auto;
  max-width: 800px;
  padding: 18px;
  letter-spacing: .3px;
}

.checkout-club-name {
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 12px;
  display: block;
}

.checkout-club-name span {
  display: block;
  font-size: 16px;
}

.checkout-club-description {
  font-style: italic;
  display: block;
  margin-bottom: 90px;
}

.checkout-club-price {
  font-size: 38px;
  margin-bottom: 12px;
  display: block;
}

.checkout-club-price-preffix {
  font-size: 16px;
  position: relative;
  top: -12px;
}

.checkout-club-price-suffix {
  font-size: 16px;
}

.checkout-club-services {
  background-color: rgba(0, 0, 0, .2);
  margin: 0 -18px 32px -18px;
  padding: 12px 30px;
}

.checkout-club-checkbox {
  margin-bottom: 12px;
}

.checkout-club-checkbox-clicked {
  color: red;
  animation: shake 0.1s;
  animation-iteration-count: 3;
}

.checkout-club-checkbox a {
  color: #000;
  text-decoration: underline;
}

.checkout-club-checkbox label {
  margin-left: 8px;
}

.checkout-club-button {
  background-color: rgb(39 211 102);
  border-radius: 8px;
  border: 0;
  box-sizing: border-box;
  color: #000;
  display: block;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 3px;
  margin: auto;
  padding: 12px 16px;
  text-align: center;
  text-transform: uppercase;
}

@keyframes shake {
  0% {margin-left: 0;}
  25% {margin-left: 5px;}
  50% {margin-left: 0;}
  75% {margin-left: -5px;}
  100% {margin-left: 0;}
}

/*
==========
COMPONENTS
==========
*/

/* INFORMATIONS */
.informations {
  text-align: center;
  margin: 12px;
}

.informations img {
  width: 32px;
  height: 32px;
}

.informations span {
  position: relative;
  top: -10px;
  margin-left: 12px;
  letter-spacing: 1px;
}

.informations .whatsapp {
  display: block;
}

.informations .whatsapp img {
  width: 18px;
  height: 18px;
}

.informations .whatsapp span {
  top: -4px;
}

/* CLUB-LIST */
.club-list {
  display: flex;
  justify-content: center;
}

.club-list-title {
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  margin-bottom: 12px;
}

.club-list-container {
  padding: 0 0 12px 12px;
  display: flex;
  overflow: scroll;
}

.club-list-card {
  border-radius: 12px;
  overflow: hidden;
  border: 1px solid #000;
  width: 240px;
  min-width: 240px;
  margin-right: 12px;
  position: relative;
  text-align: left;
}

.club-list-card-best-seller-icon {
  width: 72px;
  height: auto;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.club-list-card-header {
  background: rgba(255, 255, 255, 1);
  color: #000000;
  height: 180px;
  padding: 12px;
  position: relative;
}

.club-list-card-header-prefix {
  display: block;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.3px;
}

.club-list-card-header-title {
  display: block;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.3px;
  margin-bottom: 8px;
}

.club-list-card-header-description {
  display: block;
  font-size: 12px;
  font-weight: 200;
  letter-spacing: 0.3px;
  margin-bottom: 6px;
}

.club-list-card-header-price {
  bottom: 8px;
  position: absolute;
}

.club-list-card-header-price-description {
  display: block;
  font-weight: 900;
  font-size: 12px;
  font-weight: 200;
  letter-spacing: 0.9px;
}

.club-list-card-header-price-currency {
  position: relative;
  top: -18px;
}

.club-list-card-header-price-value {
  font-size: 42px;
}

.club-list-card-header-price-exceptions {
  display: block;
  font-size: 11px;
  font-style: italic;
  font-weight: 200;
  letter-spacing: 0.3px;
}

.club-list-card-content {
  background-color: #000;
  padding-top: 1px;
}

.club-list-card-content ul {
  list-style-type: circle;
  font-size: 12px;
  padding-left: 24px;
  height: 72px;
}

.club-list-card-button {
  border-top: 1px solid #fff;
  padding: 8px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 12px;
  display: block;
  background-color: #fff;
  color: #000;
  font-weight: 600;
  width: 100%;
  border: 0;
  box-sizing: border-box;
}

.club-list-card-button:visited {
  color: #000;
}

.club-list-card-button:link {
  color: #000;
}

.club-list-card-button:hover {
  background-color: #fffaae;
}

/* LOADING */
.loading-container {
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}

.loading-icon {
  height: auto;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 348px;
}

/* MODAL */
.modal-background {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 998;
}

.modal-container {
  background-color: #fff;
  border-radius: 20px;
  box-sizing: border-box;
  color: #000;
  left: 50%;
  max-width: 690px;
  overflow: hidden;
  padding: 18px;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.modal-close {
  position: absolute;
  top: 8%;
  right: 8px;
  background: rgba(0, 0, 0, .4);
  color: #fff;
  font-size: 12px;
  border-width: 0;
  border-radius: 100%;
  padding: 8px;
  width: 30px;
  height: 28px;
  text-align: center;
}

.modal-button {
  border-radius: 8px;
  background: rgba(0, 0, 0, .4);
  color: #fff;
  text-transform: uppercase;
  margin: 4px;
  padding: 4px 16px;
}

/* EXPERIENCE */
.experience-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(100, 100, 100, .9);
  z-index: 999;
}

.experience-modal {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 690px;
  overflow: hidden;
  border-radius: 20px;
  padding: 18px;
  box-sizing: border-box;
  background-position: center center;
}

.experience-close {
  position: absolute;
  top: 18px;
  right: 18px;
  background: rgba(255, 255, 255, .2);
  color: #fff;
  font-size: 12px;
  border-width: 0;
  border-radius: 100%;
  padding: 8px;
  width: 30px;
  height: 28px;
  text-align: center;
}

.experience-title {
  font-size: 34px;
  font-weight: 600;
  margin: 0 0 28px;
  letter-spacing: 2px;
}

.experience-description {
  font-size: 18px;
  margin: 0 0 28px;
}

.experience-description span {
  font-weight: 600;
  color: #fff66e;
}

.experience-form-label {
  display: block;
  font-size: 18px;
  font-weight: 400;
  margin: 0 0 8px;
}

.experience-form-input {
  font-size: 18px;
  margin: 0 0 24px;
  background-color: #000;
  border-radius: 20px;
  border-width: 0;
  width: 100%;
  color: #fff;
  padding: 8px;
  box-sizing: border-box;
}

.experience-form-button {
  font-size: 18px;
  font-weight: 600;
  background-color: #fff;
  border-radius: 20px;
  border-width: 0;
  color: #000;
  padding: 8px;
  margin: 0 0 24px;
  width: 100%;
}

.experience-conditions {
  font-size: 12px;
}

/* SUBSCRIPTION MODAL */
.subscription-modal-container {
  padding: 24px 0 12px 0;
}

.subscription-modal-text {
  margin-bottom: 12px;
}

.subscription-modal-megahair-label {
  margin: 0 12px 24px 4px;
  text-transform: uppercase;
  letter-spacing: .3px;
  display: inline-block;
}

.subscription-modal-megahair-clicked {
  color: red;
  animation: shake 0.1s;
  animation-iteration-count: 3;
}

.subscription-modal-checkbox-label {
  margin: 0px 0px 12px 4px;
  display: inline-block;
}

.subscription-modal-checkbox-clicked {
  color: red;
  animation: shake 0.1s;
  animation-iteration-count: 3;
}

.subscription-modal-checkbox-label a {
  color: #000;
  text-decoration: underline;
}

.subscription-modal-button {
  background-color: rgb(39 211 102);
  border-radius: 8px;
  border: 0;
  box-sizing: border-box;
  color: #000;
  display: block;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 3px;
  margin: auto;
  padding: 12px 16px;
  text-align: center;
  text-transform: uppercase;
}
